import {
  LupaFacet,
  LupaFacetMapItem,
  LupaFacetTypes,
  StatsRange,
} from '@lib/types/lupa'

export default ({
  filters,
  facets,
}: {
  filters: LupaFacetMapItem
  facets: LupaFacet[]
}) => {
  const newFilters = new Map()

  Object.keys(filters).forEach((key) => {
    const filterType = facets.find((facet) => facet.key === key)?.type

    if (filterType === LupaFacetTypes.Stats) {
      const { gte, lte } = filters[key] as unknown as StatsRange

      if (isNaN(+gte) || isNaN(+lte)) {
        return
      }

      const statsRange = [+gte, +lte]

      return newFilters.set(key, {
        value: statsRange,
        type: filterType,
      })
    }

    newFilters.set(key, {
      value: filters[key],
      type: filterType,
    })
  })

  return {
    temporaryFilters: newFilters,
    selectedFilters: newFilters,
  }
}
